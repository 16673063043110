"use strict"

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default {
    state: () => ({
        campaign: {
            formData: {
                songDetails: {
                    songName: null,
                    spotifyUrl: null,
                    spotifyTrackId: null,
                    spotifyPreviewUrl: null,
                    songLanguage: null,
                    selectedGenres: [],
                    spotifyTrackImageUrl: null,
                    releaseDate: null
                },
                artistDetails: {
                    artists: [],
                    socialMediaLink: 'https://www.',
                    homeTown: null,
                    country: null
                },
                campaignDetails: {
                    pitchComment: null,
                    campaignMinBudget: null,
                    campaignMaxBudget: null,
                    possibleCampaignBudgets: [],
                    campaignBudget: null,
                    campaignLaunchDate: null,
                    couponDiscount: null,
                    couponCode: null
                },
                rerunDetails: {
                    // these field are temporary and will be not cached
                    fileStillExistsOnRemote: false,
                    isFileFromCache: false,
                    campaignUuid: null
                },
                mp3File: {
                    name: null,
                    url: null,
                    size: null,
                    type: null,
                    remoteName: null,
                    trackDuration: null,
                    uploadedAt: null
                },
            },
            stepper: {
                currentStep: 1,
                stepsCompleted: [],
                maxSteps: 3
            },
            status: 'edit',
            fileValid: false, // will be used to check if file needs to reupload (because of s3 lifecycle rules)
            draftId: null,
            cacheLoaded: false,
            isReRun: false
        },
        billingFields: {
            fullLegalName: '',
            companyName: '',
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            userState: '',
            postCode: '',
            country: '',
            taxId: ''
        },
        cacheLoad: false
    }),
    mutations,
    actions,
    getters,
}
