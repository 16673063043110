import axios from "axios";
import moment from "moment";

export default {
    completeSteps({commit}) {
        commit('changeCampaignStatusToReview')
    },
    changeSteps({commit}) {
        commit('changeCampaignStatusToEdit')
    },
    async clearCampaignFields({ commit }){
        commit('clearCampaignFields')
    },
    async getAudioDuration({commit}, audio) {
        return new Promise((resolve, reject) => {
            audio.onloadedmetadata = function() {
                resolve(audio.duration)
            };
        })
    },
    async setCampaignTrack(context, payload) {

        // calculate track duration
        let audio = new Audio(payload.uploadResponse.fileLink)
        let trackDuration = await context.dispatch('getAudioDuration', audio)

        let uploadedFile = {
            fileLink: payload.uploadResponse.fileLink,
            remoteFileName: payload.uploadResponse.fileName,
            fileName: payload.mp3File.name,
            fileSize: payload.mp3File.size,
            fileType: payload.mp3File.type,
            trackDuration: trackDuration,
            uploadedAt: moment().format('YYYY-MM-DD')
        }

        context.commit('changeMp3File', uploadedFile)

        const response = await context.dispatch('updateCampaignCache')

        if(response.ok) {
            return uploadedFile
        }

        return null
    },
    async removeCampaignTrack({commit, getters}) {
        return axios.delete('/api/campaign/mp3file/remove', {
            remoteName: getters.getCampaignTrack.remoteName
        })
            .then(({data}) => {
                commit('clearMp3File')
                return data
            })
            .catch(err => {
                //console.log(err)
            });
    },
    async checkIfFileExists({getters}) {
        return await axios.get(`/api/campaign/check-file-on-remote?filePath=${getters.getCampaignTrack.remoteName}`)
            .then(({data}) => {
                return true
            })
            .catch(err => {
                return false
            });
    },
    goBackToReview({commit}) {
        commit('changeCampaignStatusToReview')
    },
    async loadUsersBillingAddressIfExists({commit}) {
        return await axios.get('/api/user/billing')
            .then((response) => {
                if(response.status === 200 && response.data !== undefined){
                    if(response.data.hasBillingAddress === true){

                        let billingFields = {
                            fullLegalName: response.data.billingAddress.fullLegalName,
                            companyName: response.data.billingAddress.companyName,
                            addressLineOne: response.data.billingAddress.addressLineOne,
                            addressLineTwo: response.data.billingAddress.addressLineTwo,
                            city: response.data.billingAddress.city,
                            userState: response.data.billingAddress.userState,
                            postCode: response.data.billingAddress.postCode,
                            country: response.data.billingAddress.country,
                            taxId: response.data.billingAddress.taxId
                        }
                        commit('updateBillingFields', billingFields)
                        return billingFields
                    }
                }
            })
            .catch((error) => {
                //console.log("Cannot load billing address")
            })
    },
    async storeCampaign({getters, dispatch}, sessionId) {
        return await axios.post(`/api/campaign/store/${sessionId}` )
            .then(({data}) => {
                // clearCampaignFields is not mandatory anymore since its not in a local storage
                dispatch('completeCampaign', {
                    campaignId: data.campaign_uuid,
                    draftId: data.draft_id
                })
            })
            .catch((error) => {
                return false
            })
    },
    async storeCampaignSession({getters, dispatch}, formData) {
        let fileLink = null
        let trackDuration = null
        if(getters.hasCampaignTrack){
            fileLink = getters.getCampaignTrack.url
            trackDuration = getters.getCampaignTrack.trackDuration.toFixed(2)
        }

        let campaignData = {
            //_token: $('meta[name="csrf-token"]').attr('content'),
            draftId: getters.campaignDraftId,
            fileLink: fileLink,
            songName: getters.campaignData.songDetails.songName,
            songLanguage: getters.campaignData.songDetails.songLanguage,
            spotifyUrl: getters.campaignData.songDetails.spotifyUrl,
            spotifyTrackId: getters.campaignData.songDetails.spotifyTrackId,
            imageUrl: getters.campaignData.songDetails.spotifyTrackImageUrl,
            previewUrl: getters.campaignData.songDetails.spotifyPreviewUrl,
            genres: JSON.stringify(getters.campaignData.songDetails.selectedGenres),
            releaseDate: getters.campaignData.songDetails.releaseDate,
            durationMs: trackDuration,
            socialMediaUrl: getters.campaignData.artistDetails.socialMediaLink,
            homeTown: getters.campaignData.artistDetails.homeTown,
            artists: JSON.stringify(getters.campaignData.artistDetails.artists),
            country: getters.campaignData.artistDetails.country,
            amount: getters.campaignData.campaignDetails.campaignBudget,
            couponCode: getters.campaignData.campaignDetails.couponCode,
            launchDate: getters.campaignData.campaignDetails.campaignLaunchDate,
            pitchComment: getters.campaignData.campaignDetails.pitchComment,

            isReRun: getters.isReRun,
            originalCampaignUuid: getters.campaignData.rerunDetails.campaignUuid,

            fullLegalName: formData.fullLegalName,
            companyName: formData.companyName,
            addressLineOne: formData.addressLineOne,
            addressLineTwo: formData.addressLineTwo,
            city: formData.city,
            state: formData.userState,
            postCode: formData.postCode,
            billingCountry: formData.country,
            taxId: formData.taxId
        }

        return await axios.post('/api/campaign/session/store', campaignData )
            .then(({data}) => {
                return data.sessionId
            })
            .catch((error) => {
                return false
            })
    },
    async completeCampaign({dispatch}, payload) {
        return await dispatch('removeCampaignCache', payload.draftId)
    },
    async storeCampaignDraft(context, payload) {
        if(payload.content === 'songDetails'){
            context.commit('changeCampaignSongDetailFields', payload.data)
        }
        else if(payload.content === 'artistDetails'){
            context.commit('changeCampaignArtistDetailFields', payload.data)
        }
        else if(payload.content === 'campaignDetails'){
            context.commit('changeCampaignCampaignDetailFields', payload.data)
            context.commit('changeCampaignBudgets', payload.data)
        }

        return await context.dispatch('updateCampaignCache')
    },
    async updateCampaignCache(context, _) {

        let campaignData = context.getters.campaignCacheData

        // rerun specifications
        if(context.getters.isReRun === true){
            campaignData.isReRun = true

            // if file is from cache, use the existing draft id
            if(context.getters.campaignData.rerunDetails.isFileFromCache){
                campaignData.draftId = context.getters.campaignData.rerunDetails.campaignUuid
                campaignData.customNewDraftId = null
            }
            else {
                campaignData.customNewDraftId = context.getters.campaignData.rerunDetails.campaignUuid

                context.commit("changeCampaignRerunDetails", {
                    fileStillExistsOnRemote: true,
                    isFileFromCache: true,
                    campaignUuid: context.getters.campaignData.rerunDetails.campaignUuid
                })
            }
        }

        return await axios.put('/api/campaign/cache/item/update', campaignData )
            .then(({data}) => {
                if(context.state.campaign.draftId === null) { // && campaignData.customNewDraftId !== undefined
                    context.commit('updateDraftId', data.draftId)

                    const urlParams = new URLSearchParams(window.location.search);
                    const draftId = urlParams.get("draftId")

                    if(!draftId){
                        window.history.pushState(data.draftId, "Campaign Draft", window.location.href + '?draftId=' + data.draftId)
                    }
                }
                return true
            })
            .catch((error) => {
                return false
            })
    },
    async checkFileAgeAndRestore(context, _) {
        const fileExists = await context.dispatch('checkIfFileExists')
        const days = moment().diff(moment(context.getters.getCampaignTrack.uploadedAt), 'days')

        if(!fileExists ){
            context.commit('fileIsValid', false)
            context.commit('clearMp3File')
        }
        else if(days >= 30){
            const campaignTrack = context.getters.getCampaignTrack
            await context.dispatch('restoreFile', campaignTrack)
        }
         else {
             context.commit('fileIsValid', true)
         }
    },
    async restoreFile(context, campaignTrack) {

        return await axios
            .put('/api/campaign/mp3file/restore', {
                remoteName: campaignTrack.remoteName,
                songName: campaignTrack.name
            })
            .then(({data}) => {
                context.dispatch('setCampaignTrack', {
                    mp3File: {
                        name: campaignTrack.name,
                        size: campaignTrack.size,
                        type: campaignTrack.type
                    },
                    uploadResponse: data
                })

                return true
            })
            .catch(err => {
                // console.log(err)
            })
    },
    async removeCampaignCache(context, draftId) {
        return await axios.delete(`/api/campaign/cache/item/delete/${draftId}`)
            .then(({data}) => {
                return true
            })
            .catch((error) => {
                return false
            })
    },
    async loadCampaignDraftFromCache(context, draftId) {
        return await axios.get(`/api/campaign/cache/item/${draftId}`)
            .then(({data}) => {
                // campaign can be only in edit mode and step 3 maximum for a draft
                // because the release date can be in the past and cannot be cached therefore
                // and also the possible budgets will be always changing
                data.status = 'edit'

                if(data.stepper.currentStep > 3){
                    data.stepper.currentStep = 3
                }

                const isLaunchDateInFuture = moment().isBefore(data.formData.campaignDetails.campaignLaunchDate)
                if(!isLaunchDateInFuture) {
                    data.formData.campaignDetails.campaignLaunchDate = null
                }

                context.commit('changeMp3File', {
                    fileName: data.formData.mp3File.name,
                    fileLink: data.formData.mp3File.url,
                    fileSize: data.formData.mp3File.size,
                    fileType: data.formData.mp3File.type,
                    remoteFileName: data.formData.mp3File.remoteName,
                    trackDuration: data.formData.mp3File.trackDuration,
                    uploadedAt: data.formData.mp3File.uploadedAt
                })

                context.commit("changeCampaignSongDetailFields", data.formData.songDetails)

                context.commit("changeCampaignArtistDetailFields", data.formData.artistDetails)

                context.commit("changeCampaignCampaignDetailFields", data.formData.campaignDetails)

                context.commit("changeStepper", data.stepper)
                context.commit("changeStatus", data.status)
                context.commit("updateDraftId", data.draftId)

                context.dispatch('checkFileAgeAndRestore')

                context.commit('cacheLoaded', true)

                return true
            })
            .catch((error) => {
                //console.log(error)
                return false
            })
    },
    async loadBudgetsFromSelectedGenres(context, selectedGenres) {
        if (selectedGenres && selectedGenres.length > 0) {
            return await axios.get('/api/campaign/available-playlists-and-budget', {
                params: {
                    selectedGenres: selectedGenres
                }
            })
            .then(({data}) => {
                context.commit('changeCampaignBudgets', {
                    campaignMinBudget: context.getters.getSliderMinBudget(data),
                    campaignMaxBudget:  context.getters.getSliderMaxBudget(data),
                    possibleCampaignBudgets:  data,
                    campaignBudget:  context.getters.getSliderMinBudget(data),
                })

                return true
            })
            .catch(e => {
                //console.log("error",e.response)
                return false
            })
        }
    },
    async loadBudgetsFromSelectedGenresToExtendCampaign(context, payload) {
        if (payload.selectedGenres && payload.selectedGenres.length > 0 && payload.campaignUuids.length > 0) {
            return await axios.get('/api/campaign/available-playlists-and-budget-ext-campaign', {
                params: {
                    selectedGenres: payload.selectedGenres,
                    campaignUuids: payload.campaignUuids,
                }
            })
                .then(({data}) => {
                    context.commit('changeCampaignBudgets', {
                        campaignMinBudget: context.getters.getSliderMinBudget(data),
                        campaignMaxBudget:  context.getters.getSliderMaxBudget(data),
                        possibleCampaignBudgets:  data,
                        campaignBudget:  context.getters.getSliderMinBudget(data),
                    })

                    return true
                })
                .catch(e => {
                    //console.log("error",e.response)
                    return false
                })
        }
    },
    async changeToCheckout(context, draftId) {
        if(
            context.getters.campaignStepper.stepsCompleted.includes(1)
            && context.getters.campaignStepper.stepsCompleted.includes(2)
            && context.getters.campaignStepper.stepsCompleted.includes(3)
        ) {
            return await axios.get(`/api/campaign/cache/item/${draftId}`)
                .then(({data}) => {

                    let cachedCampaignBudget = parseFloat(data.formData.campaignDetails.campaignBudget)

                    context.commit('changeCampaignBudgets', {
                        campaignBudget: cachedCampaignBudget
                    })
                    context.commit('changeToCheckout')
                    return true
                })
                .catch((error) => {
                    //console.log(error)
                    return false
                })
        }
    },
    async getCampaign(context, campaignUuid) {
        return await axios.get(`/api/campaign/details/${campaignUuid}`)
            .then(({data}) => {
                context.commit('changeMp3File', {
                    fileName: data.fileName,
                    fileLink: data.fileUrl,
                    fileSize: data.fileSize,
                    fileType: data.fileType,
                    remoteFileName: data.fileName,
                    trackDuration: data.durationMs,
                    uploadedAt: data.firstLaunchDate
                })

                context.commit("changeCampaignSongDetailFields", {
                    songName: data.songName,
                    spotifyUrl: data.spotifyUrl,
                    spotifyTrackId: data.spotifyTrackId,
                    spotifyPreviewUrl: data.spotifyPreviewUrl,
                    songLanguage: data.songLanguage,
                    selectedGenres: data.genres,
                    spotifyTrackImageUrl: data.albumImageUrl,
                    releaseDate: data.releaseDate,
                })

                context.commit("changeCampaignArtistDetailFields", {
                    artists: data.artists,
                    socialMediaLink: data.socialMediaLink,
                    homeTown: data.hometown,
                    country: data.country,
                })

                context.commit("changeCampaignCampaignDetailFields", {
                    pitchComment: data.pitchComment,
                    //campaignLaunchDate: data.originalLaunchDate
                })

                context.dispatch('loadBudgetsFromSelectedGenresToExtendCampaign', {
                    selectedGenres: data.genres,
                    campaignUuids: data.campaignUuids
                })

                context.commit("changeCampaignRerunDetails", {
                    fileStillExistsOnRemote: data.fileExists,
                    isFileFromCache: data.isFileFromCache,
                    campaignUuid: data.originalCampaignUuid
                })
            })
    },
    async storeCampaignReRunDraft(context, payload) {

        await context.dispatch('storeCampaignDraft', {
            content: 'songDetails',
            data: {
                songName: context.getters.campaignData.songDetails.songName,
                spotifyUrl: context.getters.campaignData.songDetails.spotifyUrl,
                spotifyTrackId: context.getters.campaignData.songDetails.spotifyTrackId,
                spotifyPreviewUrl: context.getters.campaignData.songDetails.spotifyPreviewUrl,
                songLanguage: context.getters.campaignData.songDetails.songLanguage,
                selectedGenres: context.getters.campaignData.songDetails.selectedGenres,
                spotifyTrackImageUrl: context.getters.campaignData.songDetails.spotifyTrackImageUrl,
                releaseDate: context.getters.campaignData.songDetails.releaseDate
            }
        })

        await context.dispatch('storeCampaignDraft', {
            content: 'artistDetails',
            data: {
                artists: context.getters.campaignData.artistDetails.artists,
                socialMediaLink: context.getters.campaignData.artistDetails.socialMediaLink,
                homeTown: context.getters.campaignData.artistDetails.homeTown,
                country: context.getters.campaignData.artistDetails.country,
            }
        })

        await context.dispatch('storeCampaignDraft', {
            content: 'campaignDetails',
            data: {
                pitchComment: context.getters.campaignData.campaignDetails.pitchComment,
                campaignBudget: payload.campaignBudget,
                campaignLaunchDate: payload.campaignLaunchDate,
            }
        })
    },

}
