export default {
    updateCouponCode(state, { couponCode, couponDiscount }) {
        state.campaign.formData.campaignDetails.couponCode = couponCode
        state.campaign.formData.campaignDetails.couponDiscount = couponDiscount
    },
    changeCampaignSongDetailFields(state, newFields) {
        state.campaign.formData.songDetails.songName = newFields.songName
        state.campaign.formData.songDetails.spotifyUrl = newFields.spotifyUrl
        state.campaign.formData.songDetails.spotifyTrackId = newFields.spotifyTrackId
        state.campaign.formData.songDetails.spotifyPreviewUrl = newFields.spotifyPreviewUrl
        state.campaign.formData.songDetails.songLanguage = newFields.songLanguage
        state.campaign.formData.songDetails.selectedGenres = newFields.selectedGenres
        state.campaign.formData.songDetails.spotifyTrackImageUrl = newFields.spotifyTrackImageUrl
        state.campaign.formData.songDetails.releaseDate = newFields.releaseDate
    },
    changeCampaignArtistDetailFields(state, newFields) {
        state.campaign.formData.artistDetails.artists = newFields.artists
        state.campaign.formData.artistDetails.socialMediaLink = newFields.socialMediaLink
        state.campaign.formData.artistDetails.homeTown = newFields.homeTown
        state.campaign.formData.artistDetails.country = newFields.country
    },
    changeCampaignCampaignDetailFields(state, newFields) {
        state.campaign.formData.campaignDetails.pitchComment = newFields.pitchComment
        state.campaign.formData.campaignDetails.campaignLaunchDate = newFields.campaignLaunchDate
        state.campaign.formData.campaignDetails.couponDiscount = newFields.couponDiscount
        state.campaign.formData.campaignDetails.couponCode = newFields.couponCode
    },
    changeCampaignBudgets(state, newFields) {
        state.campaign.formData.campaignDetails.campaignMinBudget = newFields.campaignMinBudget
        state.campaign.formData.campaignDetails.campaignMaxBudget = newFields.campaignMaxBudget
        state.campaign.formData.campaignDetails.possibleCampaignBudgets = newFields.possibleCampaignBudgets
        state.campaign.formData.campaignDetails.campaignBudget = newFields.campaignBudget
    },
    changeCampaignRerunDetails(state, payload) {
        state.campaign.formData.rerunDetails.fileStillExistsOnRemote = payload.fileStillExistsOnRemote
        state.campaign.formData.rerunDetails.isFileFromCache = payload.isFileFromCache
        state.campaign.formData.rerunDetails.campaignUuid = payload.campaignUuid
        state.campaign.isReRun = true
    },
    changeMp3File(state, file) {
        state.campaign.formData.mp3File.name = file.fileName
        state.campaign.formData.mp3File.url = file.fileLink
        state.campaign.formData.mp3File.size = file.fileSize
        state.campaign.formData.mp3File.type = file.fileType
        state.campaign.formData.mp3File.remoteName = file.remoteFileName
        state.campaign.formData.mp3File.trackDuration = file.trackDuration
        state.campaign.formData.mp3File.uploadedAt = file.uploadedAt
        state.campaign.fileValid = true
    },
    clearMp3File(state){
        state.campaign.formData.mp3File = {
            name: null,
            url: null,
            size: null,
            type: null,
            remoteName: null,
            trackDuration: null,
            uploadedAt: null
        }
        state.campaign.formData.fileValid = false
    },
    changeCampaignField(state, newField) {
        state.campaign.formData[newField.name] = newField.value
    },
    completeCampaignStep(state, completedStep) {
        if(!state.campaign.stepper.stepsCompleted.includes(completedStep)){
            state.campaign.stepper.stepsCompleted.push(completedStep)
        }
    },
    changeToCampaignStep(state, newStep) {
        state.campaign.stepper.currentStep = newStep
    },
    changeCampaignStatusToReview(state){
        state.campaign.status = 'review'
    },
    changeCampaignStatusToCheckout(state){
        state.campaign.status = 'checkout'
    },
    changeCampaignStatusToEdit(state){
        state.campaign.status = 'edit'
    },
    updateBillingFields(state, billingFields) {
        state.billingFields.fullLegalName = billingFields.fullLegalName
        state.billingFields.companyName = billingFields.companyName
        state.billingFields.addressLineOne = billingFields.addressLineOne
        state.billingFields.addressLineTwo = billingFields.addressLineTwo
        state.billingFields.city = billingFields.city
        state.billingFields.userState = billingFields.userState
        state.billingFields.postCode = billingFields.postCode
        state.billingFields.country = billingFields.country
        state.billingFields.taxId = billingFields.taxId
    },
    updateDraftId(state, draftId) {
        state.campaign.draftId = draftId
    },
    changeStepper(state, payload) {
        state.campaign.stepper.currentStep = payload.currentStep
        state.campaign.stepper.stepsCompleted = payload.stepsCompleted
    },
    fileIsValid(state, isValid) {
        if(isValid) {
            state.campaign.fileValid = true
        }
        else {
            state.campaign.stepper.currentStep = 1
            state.campaign.stepper.stepsCompleted = []
            state.campaign.status = 'edit'
        }
    },
    changeStatus(state, status) {
        state.campaign.status = status
    },
    cacheLoaded(state, payload) {
        state.campaign.cacheLoaded = payload
    },
    changeToCheckout(state, _) {
        state.campaign.status = 'checkout'
    }
}
