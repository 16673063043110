export default {
    campaignData: state => state.campaign.formData,
    campaignCacheData: state => state.campaign,
    getCampaignTrack: state => state.campaign.formData.mp3File,
    hasCampaignTrack: (state) => {
        return (
            state.campaign.formData.mp3File.url !== null
            && state.campaign.formData.mp3File.name !== null
            && state.campaign.formData.mp3File.size !== null
            && state.campaign.formData.mp3File.type !== null
            && state.campaign.formData.mp3File.trackDuration !== null
        )
    },
    campaignStepper: state => state.campaign.stepper,
    campaignStatus: state => state.campaign.status,
    currentStep: state => state.campaign.stepper.currentStep,
    couponData: (state) => ({
        couponCode: state.campaign.formData.campaignDetails.couponCode,
        couponDiscount: state.campaign.formData.campaignDetails.couponDiscount
    }),
    billingFields: state => state.billingFields,
    isBillingFieldsEmpty: (state) => {
        const billingFields = state.billingFields
        for (const key in billingFields) {
            if (billingFields[key] !== null && billingFields[key] !== '')
                return false;
        }
        return true;
    },
    isTaxRequired: state => state.billingFields.country && state.billingFields.country === 'CH',
    billingCountry: state => state.billingFields.country,
    campaignMinBudget: state => state.campaign.formData.campaignDetails.campaignMinBudget,
    campaignMaxBudget: state => state.campaign.formData.campaignDetails.campaignMaxBudget,
    getSliderMinBudget: (_) => (possibleCampaignBudgets) => {
        return Object.keys(possibleCampaignBudgets).reduce((prev, next) => {
            let prevFloat = parseFloat(prev)
            let nextFloat = parseFloat(next)

            return (prevFloat > 0 && prevFloat < nextFloat) ? prevFloat : nextFloat
        }, 0)
    },
    getSliderMaxBudget: (_) => (possibleCampaignBudgets) => {
        return Object.keys(possibleCampaignBudgets).reduce((prev, next) => {
            let prevFloat = parseFloat(prev)
            let nextFloat = parseFloat(next)

            return (nextFloat > prevFloat) ? nextFloat : prevFloat
        }, 0)
    },
    hasPossibleBudgets: (state) => !!(
        state.campaign.formData.campaignDetails.possibleCampaignBudgets !== undefined
        && state.campaign.formData.campaignDetails.possibleCampaignBudgets
    ),
    campaignDetails: (state) => state.campaign.formData.campaignDetails,
    campaignDraftId: (state) => state.campaign.draftId,
    needsFileReUpload: (state) => {
        return (state.campaign.fileValid === false && state.campaign.formData.mp3File.remoteName !== null)
    },
    isCacheLoaded: state => state.campaign.cacheLoaded,
    isReRun: (state) => state.campaign.isReRun
}
