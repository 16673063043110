<template>
    <v-container v-if="isMobile === true">
        <v-btn @click="drawer = true">
            <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-sheet>
            <v-navigation-drawer app
                                 v-model="drawer"
                                 temporary
                                 absolute
            >


                <v-list-item class="px-2">
                    <div class="py-3">
                        <img  src="/assets/songrocket-wh-logo.svg" width="150" />
                    </div>
                </v-list-item>

                <v-divider></v-divider>

                <v-list nav dense>
                    <v-list-item
                        v-for="item in legalItems"
                        :key="item.title"
                        link
                        :href="item.path"
                        style="text-decoration: none"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </v-sheet>
    </v-container>
</template>

<script>
import routes from '../../../routes.js'

export default {
    props: {
        userRole: String,
        spotifyAccountConnected: Boolean,
        hasVerifiedEmail: Boolean,
        isAffiliatePartner: Boolean,
    },
    computed: {
        // todo vuex module
        legalItems() {
            return this.items.filter((item) => {
                if(this.userRole === 'admin'){
                    return true;
                }

                // check role
                let isLegalRole = this.userRole === item.role.find(role => role === this.userRole)

                // if role is not legal but affiliate partner is positive, we display the item nevertheless
                if(isLegalRole === false && item.affiliatePartner === true && this.isAffiliatePartner === true) {
                    return true;
                }

                // role is legal, but only display when email is verified
                if(isLegalRole && item.emailVerified === true) {
                    // check if email needs to be verified to see this link
                    if(item.emailVerified === true && this.hasVerifiedEmail === true) {
                        // only display with this with a spotify account connection e.g. playlists
                        if(item.withSpotifyAccount === true){
                            return this.spotifyAccountConnected
                        }
                    }
                    else {
                        return false
                    }
                }

                return isLegalRole

            })
        }
    },
    data () {
        return {
            drawer: false,
            items: routes,
            isMobile: false
        }
    },
    beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
    },

    mounted () {
        this.onResize()

        window.addEventListener('resize', this.onResize, { passive: true })
    },

    methods: {
        onResize () {
            this.isMobile = window.innerWidth < 600
        },
        hasPermission(item) {
            if(this.userRole === 'admin'){
                return true;
            }

            return this.userRole === item.role.find((role) => {
                return role === this.userRole
            })
        }
    },
}
</script>

<style scoped>

</style>
