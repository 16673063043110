<template>

    <div class="mt-5">
        <div class="form-group row">
            <div class="captcha mx-auto">
                <span v-html="this.captchaImg"></span>
                <button type="button" class="btn btn-danger" @click="reloadCaptcha">
                    &#x21bb;
                </button>
            </div>
        </div>

        <div class="alert alert-dismissible alert-info w-50 mx-auto" role="alert">
            You have to write the result of the arithmetic task in the text field. e.g. <strong>15</strong> when the given arithmetic task is: <i>10 + 5</i>
        </div>

        <div class="form-group row">
            <div class="col-md-6 mx-auto">
                <input v-model="captcha" type="text" placeholder="Enter Captcha Result" class="form-control songrocket-input form-rounded" name="captcha" required>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    data: () => ({
        captcha: null,
        captchaImg: null
    }),
    mounted() {
        this.reloadCaptcha()
    },
    methods: {
        reloadCaptcha() {
            axios.get(`/reload-captcha`)
            .then(({data}) => {
                this.captchaImg = data.captcha
            })
            .catch(err => {
                //console.log(err)
            })
        }
    }
}
</script>

<style scoped>

</style>
