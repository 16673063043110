"use strict"

const routes = [
    //{ title: 'Dashboard', icon: 'mdi-home', path: '/', role: ['artist', 'curator'], emailVerified: false },
    { title: 'Campaigns', icon: 'icon-campaigns', path: '/campaigns', role: ['artist', 'curator'], emailVerified: true, affiliatePartner: true },
    { title: 'Playlists', icon: 'icon-playlists', path: '/playlists', role: ['artist', 'curator'], withSpotifyAccount: true, emailVerified: true, affiliatePartner: true },
    { title: 'Reviews', icon: 'icon-reviews', path: '/reviews', role: ['curator'], emailVerified: true, affiliatePartner: false },
    { title: 'Balance', icon: 'icon-earnings', path: '/earnings', role: ['curator'], emailVerified: true, affiliatePartner: true },
    { title: 'Score', icon: 'mdi-chevron-triple-up', path: '/score', role: ['curator'], emailVerified: true, affiliatePartner: false },
    { title: 'Settings', icon: 'mdi-account', path: '/user/settings', role: ['artist', 'curator'], emailVerified: false, affiliatePartner: true },
    { title: 'Support', icon: 'icon-support', path: 'https://songrocket.com/support', role: ['artist', 'curator'], emailVerified: false, affiliatePartner: true },
    { title: 'Admin Panel', icon: 'mdi-cog', path: '/admin/users', role: ['admin'], emailVerified: true },
]

export default routes
