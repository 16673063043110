<template>

    <div>
        <v-badge
            v-if="!opened"
            color="pink"
            dot
        >
            <v-icon @click="open()" style="cursor:pointer">mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else @click="open()" style="cursor:pointer">mdi-bell</v-icon>

        <div v-if="isOpen === true" class="list-group notifications-box">
            <span v-for="(notification, index) in this.dashboardNotifications" :key="index" class="list-group-item list-group-item-action flex-column align-items-start" :class="{'active': !notification.hasRead}">
                <p class="mb-1">{{ notification.message }}</p>
                <small>{{ notification.notificationDate }}</small>
            </span>
        </div>
    </div>

</template>

<script>
import axios from 'axios'

export default {
    data () {
        return {
            dashboardNotifications: [],
            opened: true,
            isOpen: false
        }
    },
    created() {
        axios.get('/api/user/dashboard/notifications')
            .then((response) => {
                if(response.status === 200 && response.data !== undefined){
                    this.dashboardNotifications = response.data.notifications
                    const hasNew = Boolean(response.data.hasNew)

                    if(hasNew === true){
                        this.opened = false
                    }
                }
            })
            .catch( (error) => {
                //console.log(error)
            })
    },
    methods: {
        open() {
            if(this.isOpen === false)
            {
                this.isOpen = true
            }
            else{
                this.isOpen = false
            }

            if(this.opened === false){
                axios.put('/api/user/dashboard/notifications/read')
                    .then((response) => {
                        this.opened = true
                    })
                    .catch((error) => {
                        this.opened = false
                    })
            }
        }
    }
}
</script>

<style scoped>

    .notifications-box
    {
        position:absolute;
        right: 10%;
    }

    @media screen and (min-width: 1920px) {

        .notifications-box
        {
            right: 0%;
        }

    }
</style>
