"use strict"

import Vuex from 'vuex'
import Vue from 'vue'

import campaign from './modules/campaign'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        campaign,
        // todo roles module
    },
})
