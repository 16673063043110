<template>
    <div>
        <span v-if="hasBalance">Balance {{ this.balanceLabel }}</span>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        userRole: String
    },
    data () {
        return {
            balance: null
        }
    },
    computed: {
        hasBalance() {
            return (this.userRole === 'curator' || this.userRole === 'admin') && this.balance !== null
        },
        balanceLabel() {
            return `${this.balance.toFixed(2)} $`
        }
    },
    created() {
        axios.get('/api/user/dashboard/balance')
        .then(({data}) => {
            this.balance = data.balance
        })
        .catch( (error) => {
            //console.log(error)
            this.balance = null
        })
    },
    methods: {

    }
}
</script>

<style scoped>
</style>
