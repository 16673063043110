/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')

window.Vue = require('vue')

require('./axios_api_config')

// Vuex Store
import store from './store'

import Vuetify from 'vuetify'
Vue.use(Vuetify)

// Promise based dialogs
import VuejsDialog from 'vuejs-dialog'
// import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
Vue.use(VuejsDialog);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default)

/**
 * Songrocket Layout
 */
Vue.component('side-menu-mobile-component', require('./components/layout/side_menu/MobileSideMenuCompontent.vue').default)
Vue.component('side-menu-desktop-component', require('./components/layout/side_menu/SideMenuDesktopComponent.vue').default)
Vue.component('notification-component', require('./components/layout/NotificationComponent.vue').default)
Vue.component('balance-component', require('./components/layout/BalanceComponent.vue').default)

/**
 * Songrocket Components
 */

Vue.component('captcha-component', require('./components/auth/CaptchaComponent.vue').default)

// Shared (stateless components, new from 2022)
Vue.component('customer-credit-card-component', () => import('./components/shared/CustomerCreditCardComponent'))

// User Settings
Vue.component('settings-component', () => import('./components/user/SettingsComponent'))
Vue.component('user-profile-component', () => import('./components/user/settings/UserProfileComponent'))
Vue.component('password-component', () => import('./components/user/settings/PasswordComponent'))
Vue.component('email-component', () => import('./components/user/settings/EmailComponent'))
Vue.component('billing-address-component', () => import('./components/user/settings/BillingAddressComponent'))
Vue.component('credit-cards-component', () => import('./components/user/settings/CreditCardsComponent'))
Vue.component('delete-account-component', () => import('./components/user/settings/DeleteAccountComponent'))
Vue.component('birthday-picker-component', () => import('./components/user/settings/BirthdayPickerComponent'))
Vue.component('spotify-account-connection-component', () => import('./components/user/settings/SpotifyAccountConnectionComponent'))
Vue.component('curator-availability-component', () => import('./components/user/settings/CuratorAvailabilityComponent'))
Vue.component('user-agents-component', () => import('./components/user/settings/UserAgentsComponent'))
Vue.component('affiliate-program-component', () => import('./components/user/settings/AffiliateProgramComponent'))

// Campaign Overview + Stepper Components
Vue.component('campaign-component', () => import('./components/campaign/CampaignComponent'))
Vue.component('campaigns-component', () => import('./components/campaign/CampaignsComponent'))
Vue.component('campaign-result-component', () => import('./components/campaign/CampaignResultComponent'))

// Campaign Rerun Components
Vue.component('campaign-rerun-component', () => import('./components/campaign/CampaignReRunComponent'))
Vue.component('campaign-rerun-details-component', () => import('./components/campaign/rerun/CampaignDetailsComponent'))

Vue.component('campaign-stepper-component', () => import('./components/campaign/CampaignStepperComponent'))
Vue.component('campaign-overview-component', () => import('./components/campaign/stepper/CampaignOverviewComponent'))
Vue.component('campaign-checkout-component', () => import('./components/campaign/stepper/CampaignCheckoutComponent'))
Vue.component('payment-details-component', () => import('./components/campaign/stepper/PaymentDetailsComponent'))
Vue.component('campaign-song-details-component', () => import('./components/campaign/stepper/SongDetailsComponent'))
Vue.component('campaign-artist-details-component', () => import('./components/campaign/stepper/ArtistDetailsComponent.vue'))
Vue.component('campaign-details-component', () => import('./components/campaign/stepper/CampaignDetailsComponent.vue'))
Vue.component('campaign-completion-component', () => import('./components/campaign/stepper/CampaignCompletionComponent.vue'))

// Playlists
Vue.component('playlists-component', () => import('./components/playlists/PlaylistsComponent'))
Vue.component('playlist-tracks-component', () => import('./components/playlists/PlaylistTracksComponent'))
Vue.component('playlist-genres-component', () => import('./components/playlists/PlaylistGenresComponent'))

// Reviews
Vue.component('reviews-component', () => import('./components/reviews/ReviewsComponent'))
Vue.component('review-component', () => import('./components/reviews/ReviewComponent'))

// Curator Earnings, Score and Payout Requests
Vue.component('curator-earnings-component', () => import('./components/curator/EarningsComponent'))
Vue.component('curator-score-component', () => import('./components/curator/CuratorScoreComponent'))
Vue.component('curator-payout-request-component', () => import('./components/curator/PayoutRequestComponent'))
Vue.component('curator-balance-component', () => import('./components/curator/BalanceComponent'))
Vue.component('curator-account-component', () => import('./components/user/transferwise/AccountComponent'))
Vue.component('currency-component', () => import('./components/user/transferwise/CurrencyComponent'))
Vue.component('account-by-requirements-component', () => import('./components/user/transferwise/accounts/AccountByRequirementsComponent'))
Vue.component('account-list-component', () => import('./components/user/transferwise/AccountListComponent'))

// Admin Components
Vue.component('admin-side-menu-mobile-component', () => import('./components/layout/admin/AdminMobileSideMenuComponent'))
Vue.component('admin-side-menu-desktop-component', () => import('./components/layout/admin/AdminSideMenuDesktopComponent'))
Vue.component('admin-new-coupon-component', () => import('./components/admin/NewCouponComponent'))
Vue.component('admin-coupons-list-component', () => import('./components/admin/CouponsListComponent'))
Vue.component('admin-payout-requests-component', () => import('./components/admin/payout/PayoutRequestsComponent'))
Vue.component('admin-payout-requests-export-component', () => import('./components/admin/payout/PayoutExportComponent'))
Vue.component('admin-playlists-component', () => import('./components/admin/PlaylistsComponent'))
Vue.component('admin-playlists-log-component', () => import('./components/admin/PlaylistsLogComponent'))
Vue.component('admin-edit-playlist-genres-component', () => import('./components/admin/playlists/EditPlaylistGenresComponent'))
Vue.component('admin-edit-multiple-playlist-genres-component', () => import('./components/admin/playlists/EditMultiplePlaylistGenresComponent'))
Vue.component('admin-spotify-csv-component', () => import('./components/admin/SpotifyCSVUploadComponent'))
Vue.component('admin-users-component', () => import('./components/admin/UserListComponent'))
Vue.component('admin-campaigns-component', () => import('./components/admin/campaigns/CampaignsComponent'))
Vue.component('admin-campaign-export-component', () => import('./components/admin/campaigns/CampaignExportComponent'))
Vue.component('admin-campaign-result-component', () => import('./components/admin/campaigns/CampaignResultComponent'))
Vue.component('admin-user-component', () => import('./components/admin/users/UserComponent'))


/**
 * Other Components
 */
Vue.component('music-player-component', () => import('./components/MusicPlayerComponent.vue'))
Vue.component('billing-fields-component', () => import('./components/user/billing/BillingFieldsComponent.vue'))



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    store,
    // router: router,
    vuetify: new Vuetify({
        theme: {
            dark: true,
            themes: {
                dark: {
                    primary: '#F4511E',
                    secondary: '#bb00ff',
                    accent: '#8c9eff',
                    error: '#b71c1c',
                    darkGrey: '#363636'
                },
            },
        },
        icons: {
            iconfont: 'mdi',
        },
    })
});
